import { useMainStore } from "~~/stores/main"
import { extractDataOrFailure, useDYFetch, useDYFetchWithToken } from "./fetch"
import type { ApiListStructure, ImageSet } from "./common"
import { useDYBannerStore } from "../stores/banner"
import { useDYUserStore } from "../stores/user"
import { useDYWindowStore } from "../stores/window"
import { useDYAuth } from "./auth"
import type { DYFileFormData } from "./dy-file-uploader"
import type { DataTableEmitRank } from "../components/common/DYDataTable.vue"

export interface ListResult<T> {
  items: T[]
  totalCount: number
}

/**
 * 배너의 유형
 */
export type BannerType = "main" | 'popup_top' | 'popup_mobile_top' | 'popup' | 'popup_mobile' | 'right' | 'main_movie'

/**
 * @deprecated
 */
export interface DYBannerItem {
  "no": number,
  "type": BannerType,
  "name": string,
  "image": string,
  "background": string,
  "background2": string,
  /**
   * 이젠 사실상 pc와 모바일 url 구분이 없이 하나로 가야하는거 아닌가??
   */
  "pc_url": string,
  /**
   * @deprecated //앞으로는 pcUrl만 기준으로 쓸 예정
   */
  "mobile_url": string,
  url: string
  /**
   * 페이지를 새 탭으로 열것인지 선택여부
   */
  "target": "_self" | '_target'
  "typedPk": string
  "title_icon": string
  "title": string
  "ment": string
  "bottom_msg": boolean
  "background_color": string | null,
  "font_color": string | null,
  /**
   * 해당 fileTypedPk
   */
  "introduceMovie": string
  "bannerImage": string
  "bannerImageLazyLoad": ImageSet
  /**
   * 샘플인가?
   */
  "introduceMovieUrl": string
}

/**
 * @deprecated
 */
export interface DYBannerEntry {
  "no": number
  /**
   * 부모인 프레임 배너의 주키
   */
  "banner_no": number
  /**
   * 엔트리가 갖게 되는 배너 아이템의 주키. 아래 배너 아이템의 넘버와 동일해야 함
   */
  "banner_item_no": number
  "rank": number
  "showPriority": string,
  /**
   * 실제 배너 오픈 여부
   */
  available: boolean,
  /**
   * 관리자에게만 노출할지 여부. available이 false인 경우 이 값이 true라도 노출되지 않음
   */
  "admin_only": boolean,
  "typedPk": number,
  /**
   * 특정 배너타입인 경우 표기되는 텍스트
   */
  "ment": number,
  /**
   * dday카운터 사용 여부
   */
  "dDayOpen": 'y' | "n",
  /**
   * 노출 기한이 있는 경우 노출시작일
   */
  "start_date": string | null,
  /**
   * 노출 기한이 있는 경우 노출종료일
   */
  "end_date": string | null,
  "bannerItem": DYBannerItem
}

/**
 * 배너 단위의 정보들. 부모이자, 프레임격 정보를 소유함. 이 프레임 배너들이 여러개 있을 수 있음
 * @deprecated
 */
export interface DYBanner {
  "no": number
  "rank": number
  "page_navigation_no": number | null
  "type": BannerType
  "mode": "popup" | 'top' | 'mobile_top' | ''
  "visible_devices": string | null,
  "name": string,
  "parameter_key": string | null,
  "parameter_value": string | null,
  "available": boolean,
  /**
   * 관리자만 볼 수 있는 배너. 테스트용도
   */
  "admin_only": boolean
  "category": string,
  /**
   * 별도의 복잡한 노출 조건이 있는 경우. 개발자 사용
   */
  "exposure_condition": string,
  "size_w": number,
  "size_h": number,
  "position_top": number,
  "position_left": number,
  "bottom_msg": boolean,
  "background_color": string
  "font_color": string,
  "entries": DYBannerEntry[]
}

/**
 * @deprecated
 */
export interface DYBanners {
  /**
   * 이젠 필요없을수도.. 로고등 나옴
   */
  'top_rolling'?: DYBanner[]
  /**
   * 메인 페이지의 상단 메인에 뜨는 배너들
   */
  main?: DYBanner[]
  /**
   * 동영상 형태의 배너인 경우
   */
  main_movie?: DYBanner[]
  rolling?: DYBanner[]
  popup_top?: DYBanner[]
  popup_mobile_top?: DYBanner[]
  /**
   * 왼쪽에 뜨는 것들
   */
  popup?: DYBanner[]
  right?: DYBanner[]
}

export interface DYBannerGetListParams {

}

export interface DYBannerEntryGetListParam {

}

export function useDYBanner() {
  const mainStore = useMainStore()
  const bannerStore = useDYBannerStore()
  const userStore = useDYUserStore()
  const windowStore = useDYWindowStore()
  const { hasAuth } = useDYAuth()

  const isAdmin = hasAuth('admin')

  /**
   * 사이트에서 최초에 부를 공통&메인 배너 데이터들을 세팅
   */
  const initBanner = async () => {
    try {
      const response = await useDYFetch<DYBanners>(mainStore.bannerJson)
      bannerStore.bannerData = extractDataOrFailure(response)
    } catch (e) {

    }
  }

  const priorityCheck = (entries: DYBannerEntry[]) => { //우선 노출 조건을 따져 맞는 엔트리들을 반환
  // const priorityCheck = (entries: DYBannerItemNew[]) => { //우선 노출 조건을 따져 맞는 엔트리들을 반환
    return entries.filter((entry) => {
      if (!entry.available) return false
      if (entry.admin_only && !isAdmin) return false //어드민 온리인데 관리자 아니면 바로 false
      if (!entry.showPriority) return true //우선노출 조건을 따질 필요가 없다면 바로 true 반환

      const parsing1 = entry.showPriority?.split(',')
      let finalResult = true
      let isOuterChecked = false // 큰 묶음의 대조건이 더 이상 체크할 필요가 있는지 여부를 판별

      parsing1.forEach(function (parse2) { //, 로 나눈 하나의 완전한 조건
        const condition = parse2?.split(/[|&]/)
        let parse3Check = !parse2?.includes('||') // ||조건으로 묶여 있을 경우 기본값 false로 시작하고, &&거나 단일 조건인 경우  true로 시작해서 false 조건만 타도록
        let isInnerChecked = false // 내부 로직을 더 이상 체크할 필요가 없을 때 true가 됨

        if (!isOuterChecked) { // 아웃터체크가 true인 경우 더 이상 체크해서 값을 바꾸지 말고 바로 최종값을 구해야 함
          condition.forEach(function (parse3) { // 하나의 조건 내에서 or 또는 and 조건별 쪼개기
            if (parse3 && !isInnerChecked) { // 이너체크드가 true면 이미 확정된 거라 더이상 타면 안됨
              const baseCondition = _priorityParse(parse3) // true 또는 false를 반환하게 됨
              // console.log('베이스컨디션',baseCondition)
              if (!(parse2.includes('||')) && !baseCondition) {
                // console.log(111)
                parse3Check = false // 포문으로 여러 조건을 돌기 때문에, and 조건인 경우 하나라도 false라면 false
                isInnerChecked = true
              } else if (parse2.includes('||') && baseCondition) {
                // console.log(222)
                parse3Check = true // 포문으로 여러 조건을 돌기 때문에, or 조건인 경우 하나라도 true라면 true
                isInnerChecked = true
              } else {
                parse3Check = baseCondition
              }
            }
          })

          // console.log('파스2',parse2);
          // console.log('파스3체크',parse3Check);
          if (parse3Check && parse2.includes('||')) {
            // console.log(333)
            finalResult = true
            isOuterChecked = true
          } else if (!parse3Check && !(parse2.includes('||'))) { // 하나의 구문 결과가 false인데 ||가 없다는 것은 무조건 flase리턴하면 됨
            // console.log(444)
            finalResult = false
            isOuterChecked = true
          } else {
            finalResult = parse3Check // 이도 저도 아닌 경우엔 파이널 리절트가 파스3체크임
          }
        }
      })

      return finalResult
    })
  }

  const _priorityParse = (parseText: string) => { // 파스할 최소 단위를 넘겨서 실제 비교
    const parse = parseText?.split(':')
    const gubun = parse[0] // 쿠키, 겟 등
    // 어차피 타입스크립트로 다시 작업해야 할 부분들이라 그냥 간략하게 처리
    if (gubun === 'hidden' || gubun === 'show') { // 안보여야 하기 때문에 히든조건인 경우, false가 리턴되어야 함log(parse[1],this._hiddenCheck(parse[1]))
      return gubun === 'hidden' ? !_hiddenCheck(parse[1]) : _hiddenCheck(parse[1])
    }

    const gubunKey = parse[1] // 키값
    const gubunja = parse[2] // 부등호 구분자
    let gubunValue: any = parse[3] // 밸류값
    if (gubunValue === 'true') { gubunValue = true }
    if (gubunValue === 'false' || gubunValue === 'undefined') { gubunValue = false }

    const checkResult = _priorityGubun(gubun, gubunKey)

    if (gubunja === '==' || gubunja === '!=') {
      switch (gubunja) {
        case '==': return checkResult === gubunValue
        case '!=': return checkResult !== gubunValue
      }
    }

    const prioVal = checkResult

    if (prioVal) {
      if (typeof prioVal === 'string' && (gubunja === 'LIKE' || gubunja === '!LIKE')) {
        switch (gubunja) {
          case 'LIKE': return prioVal.includes(gubunValue)
          case '!LIKE': return !prioVal.includes(gubunValue)
        }
      } else {
        // console.log(prioVal, gubunja, gubunValue)
        // console.log('여길 타는데', prioVal < gubunValue)
        switch (gubunja) {
          case '<=': return prioVal <= gubunValue
          case '>=': return prioVal >= gubunValue
          case '<': return prioVal < gubunValue
          case '>': return prioVal > gubunValue
        }
      }
    }

    return false
  }

  const _hiddenCheck = (hiddenTarget: string) => {
    if (hiddenTarget === 'app' || hiddenTarget === 'androidApp') { return userStore.isAndroidApp }
    if (hiddenTarget === 'app' || hiddenTarget === 'iosApp') { return userStore.isIOSApp }
    if (hiddenTarget === 'desktop') { return windowStore.isDesktop }
    if (hiddenTarget === 'mobile') { return !windowStore.isDesktop }
    return false
  }

  const _priorityGubun = (gubun: string, gubunKey: string) => { // 여기에 더 추가될 필요 있음. 쿠키 또는 쿼리값 또는 로컬 스토리지 등
    if (gubun === 'cookie') {
      return useCookie(gubunKey).value === undefined ? false : useCookie(gubunKey).value
    }
    if (gubun === 'session' && gubunKey === 'userID') { return !!userStore.id }
    if (gubun === 'server' && gubunKey === 'HTTP_USER_AGENT') { return userStore.userAgent }
    return false
  }

  return {
    initBanner, priorityCheck
  }
}

export interface DYBannerGroup {
  admin_only: boolean
  available: boolean
  created_at: string
  name: string
  no: number
  position_left: number
  position_top: number
  rank: number
  size_h: number
  size_w: number
  type: BannerType
  updated_at: string
}

export interface DYBannerItemNew {
  no: number
  group_no: number
  rank: number
  name: string
  fileTypedPk: number
  introduceMovie: number
  background: string
  background2: string
  images: ImageSet
  url: string
  introduceMovieUrl?: string
  showPriority: string
  available: boolean
  admin_only: boolean
  dDayOpen: 'y' | 'n'
  start_date: string
  end_date: string
  target: '_self' | '_blank'
  title_icon: string
  title: string
  description: string
  created_at: string
  updated_at: string
}

export interface DYBAnnerItemStoreParams {
  group_no?: number
  available: boolean
  admin_only: boolean
  start_date?: string
  end_date?: string
  dDayOpen?: boolean
  name?: string
  url?: string
  target?: '_self' | '_target'
  title_icon?: string
  title?: string
  description?: string
  introduceMovie?: string
  /**
   * 외부 유튜브 주소나 외부 이미지등을 사용하는 경우
   */
  outernalUrl?: string
  background?: string
  background2?: string
  fileTypedPk?: string
  fileTypedPkFileData?: DYFileFormData
  showPriority?: string
}

export interface DYBAnnerGroupStoreParams {
  type?: string
  available: boolean
  admin_only: boolean
}

/**
 * 실제 클라이언트에서 쓰기 위해 만들어지는 최종 형태
 */
export interface DYNewBanner {
  admin_only: boolean
  available: boolean
  created_at: string
  entries: DYBannerItemNew[]
  name: string
  no: number
  position_left: number
  position_top: number
  rank: number
  size_h: number
  size_w: number
  type: string
  updated_at: string
}

export interface DYNewBanners {
  main?: DYNewBanner
  /**
   * 동영상 형태의 배너인 경우
   */
  main_movie?: DYNewBanner
  popup_top?: DYNewBanner
  popup_mobile_top?: DYNewBanner
  /**
   * 왼쪽에 뜨는 것들
   */
  popup?: DYNewBanner
  popup_mobile?: DYNewBanner
  right?: DYNewBanner
}

export function useDYBannerApi() {
  const getListBanners = async (params?: DYBannerGetListParams) => {
    const response = await useDYFetch<ApiListStructure<DYBannerGroup>>('/mer/api/doyac-banner-groups', { method: 'get', params })
    return extractDataOrFailure(response)
  }

  const getListBannerItems = async (params?: DYBannerEntryGetListParam) => {
    const response = await useDYFetch<ApiListStructure<DYBannerItemNew>>('/mer/api/doyac-banner-items', { method: 'get', params })
    return extractDataOrFailure(response)
  }

  const storeItem = async (body?: DYBAnnerItemStoreParams) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYBannerItemNew>>('/mer/api/doyac-banner-items', { method: 'post', body })
    return extractDataOrFailure(response)
  }

  const storeGroup = async (body?: DYBAnnerGroupStoreParams) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYBannerGroup>>('/mer/api/doyac-banner-groups', { method: 'post', body })
    return extractDataOrFailure(response)
  }

  const updateItem = async (pk: number, body?: DYBAnnerItemStoreParams) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYBannerItemNew>>(`/mer/api/doyac-banner-items/${pk}`, { method: 'patch', body })
    return extractDataOrFailure(response)
  }

  const updateGroup = async (pk: number, body?: DYBAnnerGroupStoreParams) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYBannerGroup>>('/mer/api/doyac-banner-groups/' + pk, { method: 'patch', body })
    return extractDataOrFailure(response)
  }

  const deleteItem = async (pk: number) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYBannerItemNew>>(`/mer/api/doyac-banner-items/${pk}`, { method: 'delete' })
    return extractDataOrFailure(response)
  }

  const changeRank = async (params: DataTableEmitRank) => {
    const response = await useDYFetchWithToken<any>('/mer/api/doyac-banner-items/change-rank', { method: 'post', body: params })
    return extractDataOrFailure(response)
  }

  return {
    getListBanners, getListBannerItems, storeItem, updateItem, deleteItem, storeGroup, updateGroup, changeRank
  }
}