import { defineStore } from "pinia"
import { type DYBanners, type DYNewBanners } from "../composables/dy-banner"

export const useDYBannerStore = defineStore('nuxt3-module-doyac-banner', () => {
  
  const bannerData = ref<DYBanners>()
  // const bannerData = ref<DYNewBanners>()

  return {
    bannerData,
  }
})